import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { Livraison, GenFieldType } from '@/_models';

@Component({
    templateUrl: './livraison-edition.component.html',
    providers: [TranslatePipe]
})
export class LivraisonEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.livraisons.name"),
            link: "/livraisons",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['livraisonId']);
        });

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(livraisonId) {
        this.listeService.get('livraisons', livraisonId).pipe(first()).subscribe((element: Livraison) => {

            this.listeService.getAll('regions').pipe(first()).subscribe(regions => {

                this.listeService.getAll('transporteurs').pipe(first()).subscribe(transporteurs => {

                    this.listeService.getAll('typesLivraison').pipe(first()).subscribe(deliveryTypes => {


                        let listeTransporteurs = [{ id: null, description: this.translatePipe.transform('livraisons.transporteur.default') }];
                        for(let transporteur of transporteurs) {
                            listeTransporteurs.push({ id: transporteur.id, description: this.translatePipe.transform(transporteur.description) });
                        }

                        let listeTypes = [];
                        for(let deliveryType of deliveryTypes) {
                            listeTypes.push({ id: deliveryType.id, description: this.translatePipe.transform(deliveryType.description) });
                        }

                        let fields = [];

                        let isNew = false;

                        if (livraisonId == "0") {
                            isNew = true;
                            element = new Livraison();
                        }

                        this.breadcrumb.push({
                            label: (isNew ? this.translatePipe.transform('element.label') : element.id),
                            link: "/livraisons/" + livraisonId,
                            active: true
                        });

                        fields.push({
                            name: 'etatId',
                            label: this.translatePipe.transform('livraisons.edition.region'),
                            validity: true,
                            type: GenFieldType.Dropdown,
                            args: [
                                regions,
                                'id',
                                'name',
                                false,
                                true
                            ]

                            /*
                              args: [

                                  tableau de donnees,

                                  nom de l'id de la case, pour id dans la prochaine page,

                                  nom de l'attribut a utiliser comme description a afficher dans le dropdown,

                                  boolean: disabled

                                  boolean: required

                              ]
                            */
                        });

                        fields.push({
                            name: 'deliverytypeId',
                            label: this.translatePipe.transform('livraisons.edition.type'),
                            validity: true,
                            type: GenFieldType.Dropdown,
                            args: [
                                listeTypes,
                                'id',
                                'description',
                                false, 
                                true
                            ]
                        });

                        fields.push({
                            name: 'amount',
                            label: this.translatePipe.transform('livraisons.edition.amount'),
                            validity: true,
                            type: GenFieldType.InputMoney,
                            args: [false, true, 100]
                        });


                        fields.push({
                            name: 'transporteurId',
                            label: this.translatePipe.transform('livraisons.edition.transporter'),
                            validity: true,
                            type: GenFieldType.Dropdown,
                            args: [
                                listeTransporteurs,
                                'id',
                                'description',
                                false,
                                false
                            ]
                        });

                        fields.push({
                            name: 'default',
                            label: this.translatePipe.transform('livraisons.edition.default'),
                            validity: true,
                            type: GenFieldType.Checkbox,
                            args: [false, true]
                        });

                        this.config = {
                            name: "livraisons",
                            element: element,
                            root: "/livraisons",
                            backId: "",
                            isEditable: true,
                            isDeletable: true,
                            isNew: isNew,
                            isEmbeded: false,
                            fields: fields
                        };

                    })
                })


            })
        });
    }

}
