import { Component, OnInit, Input, AfterContentInit, Output, EventEmitter, } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { GenConfig, GenDisplayRow, GenDisplayColumn } from '@/_interfaces';

export const compare = (v1, v2) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;


@Component({
    selector: 'genlist',
    templateUrl: './genlist.component.html',
    styleUrls: ['./genlist.component.scss']
})
export class GenlistComponent implements OnInit {
    config: GenConfig;

    @Output() selectedRow = new EventEmitter();
    @Output() changeScreen = new EventEmitter();

    @Input('config') set configChange(value: GenConfig) {
        this.config = value;
        this.resetAll();
    }

    //Display settings
    page = 1;
    pageSize = 50;
    displayFilter: boolean = false;
    displayColumns: GenDisplayColumn[];
    searchKeywords: string = "";
    displayTableWithoutFilter: GenDisplayRow[];

    //Export
    downloadJsonHref: SafeUrl;
    downloadCsvHref: SafeUrl;

    displayTable: GenDisplayRow[];

    // Auto updater
    updateTimer;

    constructor(
        private router: Router,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit() { 
        this.pageSize = this.config.isEmbeded ? 5 : 50;
    }

    resetAll() {
        this.page = 1;
        this.displayFilter = false;
        this.searchKeywords = "";
        this.loadConfigColumns();
        if (this.config.columns.length > 0) {
            this.sort(this.config.indexColumnSort);
        }

        if(this.updateTimer != null){
            clearInterval(this.updateTimer);
        }

        if(this.config.autoRefreshData){
            this.updateTimer = setInterval(() => {
                this.updateData()
            }, 5000);
        }
    }

    updateData(){
        if(this.config.columns.length == 0 || this.displayTableWithoutFilter.length != this.config.columns[0].data.length){
            // Data has completly changed
            this.resetAll();
        } else {
            for(let rowIndex = 0; rowIndex < this.displayTableWithoutFilter.length; rowIndex++){
                for(let cellIndex = 0; cellIndex < this.displayTableWithoutFilter[rowIndex].cells.length; cellIndex++){
                    let newData = this.config.columns[cellIndex].displayFunc(this.config.columns[cellIndex].data[rowIndex].args);
                    this.displayTableWithoutFilter[rowIndex].cells[cellIndex] = newData;
                }
            }
        }
    }

    canAdd() : boolean{
        return this.config.addLimit > this.config.countLimit || this.config.addLimit == -1;
    }

    addElement() {

        if (this.config.isEmbeded) {
            this.changeScreen.emit();
        } else {
            this.router.navigate([this.config.root + '/' + 0]);
        }
    }

    goToElement(row) {

        if (this.config.isEmbeded) {
            this.selectedRow.emit(row.id);
        } else {
            this.router.navigate([ this.config.root + '/' + row.id ]);
        }
    }

    loadConfigColumns() {
        let isInit = false;
        this.displayTable = [];
        this.displayColumns = [];
        for (let column of this.config.columns) {
            let index = 0;
            this.displayColumns.push({ visibility: column.isVisible, sortDirection: 0, filter: "", isLink: column.isLink });
            for (let data of column.data) {
                let displayRow: GenDisplayRow;
                if (!isInit) {
                    displayRow = { id: data.args[0], cells: [] };
                    this.displayTable.push(displayRow);
                } else {
                    displayRow = this.displayTable[index++];
                }
                displayRow.cells.push(column.displayFunc(data.args));
            }
            isInit = true;
        }
        this.displayTableWithoutFilter = this.displayTable;
    }

    toggleFilter() {
        this.resetFilterAndSearch();
        this.displayFilter = !this.displayFilter
    }

    resetFilterAndSearch() {
        this.searchKeywords = "";
        for (let column of this.displayColumns) {
            column.filter = "";
        }
        this.displayTable = this.displayTableWithoutFilter;
    }

    applySearch() {
        function isApplyedSearch(searchKeywords) {
            return function (element) {
                let i = 0;
                for (let cell of element.cells) {
                    let toSearch = "" + cell;
                    if (toSearch.toUpperCase().includes(searchKeywords.toUpperCase())) {
                        return true;
                    }
                    i += 1;
                }
                return false;
            }
        }
        this.displayTable = this.displayTableWithoutFilter.filter(isApplyedSearch(this.searchKeywords));
    }

    applyFilter() {
        function isApplyedFilter(columns) {
            return function (element) {
                let i = 0;
                for (let cell of element.cells) {
                    let toSearch = "" + cell;
                    if (columns[i].filter.length > 0
                        && !toSearch.toUpperCase().includes(columns[i].filter.toUpperCase())) {
                        return false;
                    }
                    i += 1;
                }
                return true;
            }
        }

        this.displayTable = this.displayTableWithoutFilter.filter(isApplyedFilter(this.displayColumns));
    }

    setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    toggleSort(columnIndex: number) {
        if (this.displayColumns[columnIndex].sortDirection == 0) {

            //reset other column sort
            for (let column of this.displayColumns) {
                column.sortDirection = 0;
            }

            this.displayColumns[columnIndex].sortDirection = 1;
        }
        else {
            this.displayColumns[columnIndex].sortDirection = -this.displayColumns[columnIndex].sortDirection;
        }
    }

    sort(columnIndex: number) {
        this.toggleSort(columnIndex);

        this.displayTable = [...this.displayTable].sort((a, b) => {
            const res = compare(this.config.columns[columnIndex].sortableFunc(a.cells[columnIndex]),
                this.config.columns[columnIndex].sortableFunc(b.cells[columnIndex]));
            return this.displayColumns[columnIndex].sortDirection == 1 ? res : -res;
        });
    }

    generateJson(): any[] {
        let file = [];
        for (let row of this.displayTable) {
            let fileRow = {};
            let index = 0;
            for (let cell in row.cells) {
                if (this.displayColumns[index].visibility) {
                    fileRow[this.config.columns[index].name] = cell;
                }
                ++index;
            }
            file.push(fileRow);
        }
        return file;
    }

    generateCsv(): any[] {
        let file = [];
        let fileRow = [];
        let index = 0;
        for (let column of this.config.columns) {
            if (this.displayColumns[index].visibility) {
                fileRow.push(column.name);
            }
            ++index;
        }
        file.push(fileRow);
        for (let row of this.displayTable) {
            fileRow = [];
            index = 0;
            for (let cell of row.cells) {
                if (this.displayColumns[index].visibility) {
                    fileRow.push(cell);
                }
                ++index;
            }
            file.push(fileRow);
        }
        return file;
    }

    generateDownloadCsvUri() {
        let theJSON = JSON.stringify(this.generateCsv());

        theJSON = theJSON.split('],').join('\n');
        theJSON = theJSON.split('[').join('');
        theJSON = theJSON.split(']').join('');

        let blob = new Blob([theJSON], { type: 'text/csv' });
        let url = window.URL.createObjectURL(blob);
        let uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.downloadCsvHref = uri;
    }

    generateDownloadJsonUri() {
        let theJSON = JSON.stringify(this.generateJson());
        let blob = new Blob([theJSON], { type: 'text/json' });
        let url = window.URL.createObjectURL(blob);
        let uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.downloadJsonHref = uri;
    }
}