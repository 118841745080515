import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Produit } from '@/_models';

@Component({
    templateUrl: './product-edition.component.html',
    providers: [TranslatePipe]
})

export class ProductEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.produits.name"),
            link: "/produits",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['produitId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(produitId) {

        this.listeService.get('produits', produitId).pipe(first()).subscribe((element: Produit) => {

            this.listeService.getAll('categories').pipe(first()).subscribe(categories => {

                this.listeService.getAll('files').pipe(first()).subscribe(files => {

                    let fields = [];

                    let isNew = false;

                    if (produitId == "0") {
                        isNew = true;
                        element = new Produit();
                    }


                    this.breadcrumb.push({
                        label: (isNew ? this.translatePipe.transform('element.label') : element.title),
                        link: "/produits/" + produitId,
                        active: true
                    });

                    fields.push({
                        name: 'title',
                        label: this.translatePipe.transform('blog.edition.title'),
                        validity: true,
                        type: GenFieldType.InputString,
                        args: [false, true, 50]
                    });

                    fields.push({
                        name: 'summary',
                        label: this.translatePipe.transform('blog.edition.summary'),
                        validity: true,
                        type: GenFieldType.InputString,
                        args: [false, true, 100]
                    });

                    fields.push({
                        name: 'hidden',
                        label: this.translatePipe.transform('blog.edition.hidden'),
                        validity: true,
                        type: GenFieldType.Checkbox,
                        args: [false, true]
                    });

                    fields.push({
                        name: 'body',
                        label: this.translatePipe.transform('blog.edition.body'),
                        validity: true,
                        type: GenFieldType.InputEditor,
                        args: [false, true, 1000]
                    });

                    fields.push({
                        name: 'price',
                        label: this.translatePipe.transform('produits.edition.price'),
                        validity: true,
                        type: GenFieldType.InputMoney,
                        args: [false, true]
                    });

                    fields.push({
                        name: 'weight',
                        label: this.translatePipe.transform('produits.edition.weight'),
                        validity: true,
                        type: GenFieldType.InputMoney,
                        args: [false, true]
                    });

                    fields.push({
                        name: 'isDeliverable',
                        label: this.translatePipe.transform('produits.edition.deliverable'),
                        validity: true,
                        type: GenFieldType.Checkbox,
                        args: [false, true]
                    });

                    fields.push({
                        name: 'image',
                        label: this.translatePipe.transform('produits.edition.image'),
                        validity: true,
                        type: GenFieldType.GenSelect,
                        args: [true, false, "imageId", "id"]
                    });

                    fields.push({
                        name: 'categories',
                        label: this.translatePipe.transform('galleries.edition.categories'),
                        validity: true,
                        type: GenFieldType.Multiselect,
                        args: [categories, 'id', 'description', this.translatePipe.transform('galleries.edition.categories')]
                    });

                    fields.push({
                        name: 'images',
                        label: this.translatePipe.transform('galleries.edition.images'),
                        validity: true,
                        type: GenFieldType.Multiselect,
                        args: [files, 'id', 'name', this.translatePipe.transform('galleries.edition.images')]
                    });

                    this.config = {
                        name: "produits",
                        element: element,
                        root: "/produits",
                        backId: "",
                        isEditable: true,
                        isDeletable: true,
                        isNew: isNew,
                        isEmbeded: false,
                        fields: fields
                    };
                });
            });
        });
    }
}
