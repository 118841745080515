import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './language.component.html',
    providers: [TranslatePipe],
    styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

    config: any;

    elements: any[] = [];

    newelement: any = { slug: "", category: "" };

    isChange: boolean = false;

    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService,
        private http: HttpClient,
        private modalService: NgbModal) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.languages.name"),
            link: "/languages",
            active: true
        });
    }

    ngOnInit() {
        this.http.get(environment.apiUrl + "/config?config=lang").subscribe(response => {
            this.config = response;
            for(let category in this.config.content) {
                let newelement = { name: category, elements: [] };
                for(let element in this.config.content[category]) {
                    newelement.elements.push(element);
                }
                this.elements.push(newelement);
            }
        });
        this.isChange = false;
    }

    onBlurMethod(categoryName, element, i , value){
        this.config.content[categoryName][element][i] = value;
        this.isChange = true;
    }

    deleteElement(categoryName, element) {
        delete this.config.content[categoryName][element];
        
        for(let category of this.elements) {
            if (category.name == categoryName) {
                category.elements = category.elements.filter(function(value, index, arr){
                    return value != element;    
                });
            }
        }
        this.isChange = true;
    }

    deleteCategory(categoryName) {
        delete this.config.content[categoryName];
        
        this.elements = this.elements.filter(function(value, index, arr){
            return value.name != categoryName;
        });
        this.isChange = true;
    }

    save() {
        this.http.post(environment.apiUrl + "/config?config=lang", this.config).subscribe(response => {});
        this.isChange = false;
    }

    add() {
        if (this.config.content[this.newelement.category] == null) {
            this.config.content[this.newelement.category] = {};
            this.elements.push({ name: this.newelement.category, elements: [] });
        }
        this.config.content[this.newelement.category][this.newelement.slug] = [];
        for(let lang of this.config.langs) {
            this.config.content[this.newelement.category][this.newelement.slug].push(this.newelement[lang]);
        }
        for(let category of this.elements) {
            if (category.name == this.newelement.category) {
                category.elements.push(this.newelement.slug);
            }
        }
        this.newelement = { slug: "", category: "" };
        this.modalService.dismissAll();
        this.isChange = true;
    }

    openCategory(category, modal) {
        this.newelement.category = category;
        this.modalService.open(modal, { size: 'lg' }).result.then((result) => { this.newelement = { slug: "", category: "" }; }, (reason) => { this.newelement = { slug: "", category: "" }; });
    }

    open(modal) {
        this.modalService.open(modal, { size: 'lg' }).result.then((result) => { this.newelement = { slug: "", category: "" }; }, (reason) => { this.newelement = { slug: "", category: "" }; });
    }
}
