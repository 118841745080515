import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { User } from "@/_models";
import { AuthenticationService, TranslationService } from '@/_services';

@Component({
    selector: 'page-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    @ViewChild('searchBox', { static: false }) searchBox: ElementRef;
    currentUser: User;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private translationService: TranslationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    ngOnInit() {
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    changeLangue() {
        this.translationService.setSelectedLanguage();
    }

    /* 
        Fonction appelée lorsque le bouton recherche de la barre de recherche du header est cliqué
        ou que le bouton enter est cliqué lorsque la barre de recherche est en focus.
        Si la boite de texte n'est pas vide, naviguer vers la route de recherche
        Sinon il ne se passe rien    
    */
    searchUnit(searchKeyword) {
        //this.searchBox.nativeElement.value = "";
        if (searchKeyword != "")
            this.router.navigate([`/unites`, { search: searchKeyword }]);
    }

}
