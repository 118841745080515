import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';
import { BlogEditionComponent } from './blog-edition/blog-edition.component';
import { BlogComponent } from './blog/blog.component';
import { FileEditionComponent } from './file-edition/file-edition.component';
import { FileComponent } from './file/file.component';
import { LoginComponent } from './login';
import { UserEditionComponent } from './user-edition/user-edition.component';
import { ForgotComponent } from './forgot/forgot.component';
import { CategoryComponent } from './category/category.component';
import { CategoryEditionComponent } from './category-edition/category-edition.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryEditionComponent } from './gallery-edition/gallery-edition.component';
import { LanguageComponent } from './language/language.component';
import { MenuComponent } from './menu/menu.component';
import { ProductComponent } from './product/product.component';
import { ProductEditionComponent } from './product-edition/product-edition.component';
import { OrderComponent } from './order/order.component';
import { OrderEditionComponent } from './order-edition/order-edition.component';
import { DiscountComponent } from './discount/discount.component';
import { DiscountEditionComponent } from './discount-edition/discount-edition.component';
import { TaxComponent } from './tax/tax.component';
import { TaxEditionComponent } from './tax-edition/tax-edition.component';
import { RegionComponent } from './region/region.component';
import { RegionEditionComponent } from './region-edition/region-edition.component';
import { LivraisonComponent } from './livraison/livraison.component';
import { LivraisonEditionComponent } from './livraison-edition/livraison-edition.component';
import { UserComponent } from './user/user.component';

const routes: Routes = [
    {
        path: 'livraisons',
        component: LivraisonComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'livraisons/:livraisonId',
        component: LivraisonEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'regions/:regionId',
        component: RegionEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'regions',
        component: RegionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'taxes/:taxId',
        component: TaxEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'taxes',
        component: TaxComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'discounts/:discountId',
        component: DiscountEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'discounts',
        component: DiscountComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'commandes/:commandeId',
        component: OrderEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'commandes',
        component: OrderComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'blog/:articleId',
        component: BlogEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'blog',
        component: BlogComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'produits/:produitId',
        component: ProductEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'produits',
        component: ProductComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'files/:fileId',
        component: FileEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'files',
        component: FileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'categories/:categoryId',
        component: CategoryEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'galleries',
        component: GalleryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'galleries/:galleryId',
        component: GalleryEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'categories',
        component: CategoryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'languages',
        component: LanguageComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'menus',
        component: MenuComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'users',
        component: UserComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'users/:userId',
        component: UserEditionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot',
        component: ForgotComponent
    },
    {
        path: '',
        redirectTo: '/blog',
        pathMatch: 'full'
    },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
