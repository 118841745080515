import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenConfig, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';

@Component({
    templateUrl: './discount.component.html',
    providers: [TranslatePipe]
})
export class DiscountComponent implements OnInit {

    config: GenConfig;
    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.discounts.name"),
            link: "/discounts",
            active: true
        });

        this.config = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: false,
            columns: []
        };
    }

    ngOnInit() {

        let col_code = [];
        let col_rule = [];
        let col_apply = [];

        this.listeService.getAll('discounts').pipe(first()).subscribe(elements => {

            for (let element of elements) {

                col_code.push({ args: [element.id, element.code] });
                if (element.rule == 1) {
                    col_rule.push({ args: [ this.translatePipe.transform('discounts.rules.percent') ] });
                } else {
                    col_rule.push({ args: [ this.translatePipe.transform('discounts.rules.amount') ] });
                }
                col_apply.push({ args: [(element.apply/100)] });
            }

            this.config = {
                name: "discounts",
                root: "/discounts",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: false,
                columns: [
                    {
                        name: this.translatePipe.transform('discounts.header.code'),
                        width: 50,
                        data: col_code,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('discounts.header.rule'),
                        width: 25,
                        data: col_rule,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('discounts.header.apply'),
                        width: 25,
                        data: col_apply,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

}
