import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Article, Fichier } from '@/_models';
import { AuthenticationService } from '../_services';
import { User } from '../_models';

@Component({
    templateUrl: './blog-edition.component.html',
    providers: [TranslatePipe]
})
export class BlogEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];
    currentUser: User;

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private authenticationService: AuthenticationService,
        private translateService: TranslationService
    ) {

        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.blog.name"),
            link: "/blog",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['articleId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(articleId) {

        this.listeService.get('blog', articleId).pipe(first()).subscribe((element : Article) => {

            let fields = [];

            let isNew = false;

            if (articleId == "0") {
                isNew = true;
                element = new Article();
                element.image = new Fichier();
                element.author = this.currentUser.firstname + this.currentUser.lastname;
            }

            let langs = [];

            for (let language of this.translateService.languages) {

                langs.push({ lang: language, nom: this.translatePipe.transform('lang.name.' + language) })
            }


            this.breadcrumb.push({
                label: (isNew ? this.translatePipe.transform('element.label') : element.title),
                link: "/blog/" + articleId,
                active: true
            });

            fields.push({
                name: 'title',
                label: this.translatePipe.transform('blog.edition.title'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, true, 50]
            });

            fields.push({
                name: 'summary',
                label: this.translatePipe.transform('blog.edition.summary'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, true, 100]
            });

            fields.push({
                name: 'date',
                label: this.translatePipe.transform('blog.edition.date'),
                validity: true,
                type: GenFieldType.InputDate,
                args: [false, true]
            });

            fields.push({
                name: 'hidden',
                label: this.translatePipe.transform('blog.edition.hidden'),
                validity: true,
                type: GenFieldType.Checkbox,
                args: [false, true]
            });

            fields.push({
                name: 'lang',
                label: this.translatePipe.transform('blog.edition.lang'),
                validity: true,
                type: GenFieldType.Dropdown,
                args: [langs, 'lang', 'nom', false, true]
            });
            

            fields.push({
                name: 'body',
                label: this.translatePipe.transform('blog.edition.body'),
                validity: true,
                type: GenFieldType.InputEditor,
                args: [false, true, 1000]
            });

            fields.push({
                name: 'author',
                label: this.translatePipe.transform('blog.edition.author'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, true, 50]
            });

            fields.push({
                name: 'image',
                label: this.translatePipe.transform('blog.edition.image'),
                validity: true,
                type: GenFieldType.GenSelect,
                args: [true, false, "imageId", "id"]
            });

            this.config = {
                name: "blog",
                element: element,
                root: "/blog",
                backId: "",
                isEditable: true,
                isDeletable: true,
                isNew: isNew,
                isEmbeded: false,
                fields: fields
            };
        });
    }
}
