import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

import { GenField } from '@/_interfaces';
import { GenFieldType } from '@/_models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CATCH_STACK_VAR } from '@angular/compiler/src/output/output_ast';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'geninput',
    templateUrl: './geninput.component.html',
    styleUrls: ['./geninput.component.css']
})
export class GeninputComponent implements OnInit, AfterViewChecked {

    @Input('field') field: GenField;
    @Input('element') element: any;
    @Input('submitted') submitted: boolean;

    @Output() fileChange = new EventEmitter();
    @ViewChild('modal', { static: true }) modal: any;

    downloadKeyHref: SafeUrl;

    dataUrl: string = environment.dataUrl;
    apiUrl: string = environment.apiUrl;

    public editorConfig = {
        "editable": true,
        "spellcheck": true,
        "height": "auto",
        "minHeight": "0",
        "width": "auto",
        "minWidth": "0",
        "translate": "yes",
        "enableToolbar": true,
        "showToolbar": true,
        "placeholder": "",
        "imageEndPoint": "",
        "toolbar": [
            ["bold", "italic", "underline", "strikeThrough"],
            ["fontSize"],
            ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
            ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
            ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
            ["link", "unlink"]
        ]
    };

    constructor(private sanitizer: DomSanitizer, private modalService: NgbModal, private http: HttpClient) { }

    pdfUrl: SafeResourceUrl;

    initPdfUrl() {
        this.http.get(`${environment.apiUrl}` + '/invoice/' + this.field.args[0], {responseType:'arraybuffer'}).subscribe((response) => {
            var blob = new Blob([response], { type: 'application/pdf' });
            let newurl = window.URL.createObjectURL(blob);
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(newurl);
        });
    }

    ngOnInit() { 
        if (this.isFieldType(this.field.type, 'pdf')) {
            this.initPdfUrl();
        }
    }

    ngAfterViewChecked() {
        this.replaceFontAwesomeIcons('fa-scissors', 'fa-cut');
        this.replaceFontAwesomeIcons('fa-files-o', 'fa-copy');
        this.replaceFontAwesomeIcons('fa-repeat', 'fa-redo');
        this.replaceFontAwesomeIcons('fa-picture-o', 'fa-image');
        this.replaceFontAwesomeIcons('fa-chain-broken', 'fa-unlink');
        this.replaceFontAwesomeIcons('fa-youtube-play', 'fa-play-circle');
    }

    private replaceFontAwesomeIcons(currentClassName: string, newClassName: string) {
        const icons = document.getElementsByClassName(currentClassName);
        for (let i = 0; i < icons.length; i++) {
            icons.item(i).classList.add(newClassName);
            icons.item(i).classList.remove(currentClassName);
        }
    }

    isFieldType(type: GenFieldType, comparetype: string): boolean {

        if (type == comparetype) {
            return true;
        }
        return false;
    }

    upload(files) {
        this.fileChange.emit(files);
    }

    refund() {
        this.http.get(`${environment.apiUrl}` + '/refund/' + this.field.args[0]).subscribe((response) => { });
        this.close();
        location.reload();
    }

    selected(element) {
        this.element[this.field.name] = element;
        this.element[this.field.args[2]] = element[this.field.args[3]];
        this.close();
    }

    changeNumber(fieldname, value){
        try {
            this.element[fieldname] = (+value * 100);
        } catch(e) {}
    }

    changeTax(fieldname, value){
        try {
            this.element[fieldname] = (+value * 1000);
        } catch(e) {}
    }

    generateDownloadUri(key) {

        let blob = new Blob([key], { type: 'text/json' });
        let url = window.URL.createObjectURL(blob);
        let uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
        this.downloadKeyHref = uri;
    }

    open(modal) {
        this.modalService.open(modal, { size: 'xl' }).result.then((result) => { }, (reason) => { });
    }

    close() {
        this.modalService.dismissAll();
    }
}