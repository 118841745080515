import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenConfig, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';

@Component({
    templateUrl: './livraison.component.html',
    providers: [TranslatePipe]
})
export class LivraisonComponent implements OnInit {

    config: GenConfig;
    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.livraisons.name"),
            link: "/livraisons",
            active: true
        });

        this.config = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: false,
            columns: []
        };
    }

    ngOnInit() {

        let col_region = [];
        let col_type = [];
        let col_amount = [];
        let col_transporter = [];
        let col_default = [];

        this.listeService.getAll('livraisons').pipe(first()).subscribe(elements => {


            for (let element of elements) {

                col_region.push({ args: [element.id, element.region.name] });
                col_type.push({ args: [element.deliverytypeId,  element.type  ? this.translatePipe.transform(element.type.description) : "*"] });
                col_amount.push({ args: [element.amount > 0 ? element.amount : "*"]});
                col_transporter.push({args: [element.transporteurId, element.transporteur ? this.translatePipe.transform(element.transporteur.description) : "*"]});
                col_default.push({args: [element.id, element.default ? this.translatePipe.transform("livraisons.default.true") : this.translatePipe.transform("livraisons.default.false")]});

                // if (element.etatId != null) {
                //     for (let parElement of elements) {
                //         if (element.etatId == parElement.id) {
                //             parent = parElement.name;
                //         }
                //     }
                // }
                // Pourrait etre fixed avec le modele ?

            }

            /* 
                Config pour livraison: 
                *******************************************************
                * Region | Type de livraison | Montant | Transporteur *
                *********|*******************|*********|***************
                * Quebec |Ramassage sur place|  $29.99 |      *       *
                *--------|-------------------|---------|--------------*
                * France |    Transporteur   |    *    |   Purolator  *
                *--------|-------------------|---------|--------------*
                *--------|-------------------|---------|--------------*
                *--------|-------------------|---------|--------------*
                *--------|-------------------|---------|--------------*
                *******************************************************
            */
            this.config = {
                name: "livraisons",
                root: "/livraisons",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: false,
                columns: [
                    {
                        name: this.translatePipe.transform('livraisons.header.region'),
                        width: 20,
                        data: col_region,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('livraisons.header.type'),
                        width: 20,
                        data: col_type,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('livraisons.header.amount'),
                        width: 20,
                        data: col_amount,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('livraisons.header.transporter'),
                        width: 20,
                        data: col_transporter,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('livraisons.header.default'),
                        width: 20,
                        data: col_default,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

}
