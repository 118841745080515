import { Region } from './region';

export class Tax {
    
    id: number;
    etatId: number;
    ordre: number = 1;
    name: string;
    rate: number = 0;
    etat: Region;
    default: boolean = false;
}