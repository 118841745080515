import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Region } from '@/_models';

@Component({
    templateUrl: './region-edition.component.html',
    providers: [TranslatePipe]
})
export class RegionEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.regions.name"),
            link: "/regions",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['regionId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(regionId) {

        this.listeService.get('regions', regionId).pipe(first()).subscribe((element: Region) => {

            let regions = [];

            let vide = new Region();
            vide.id = null;
            vide.name = this.translatePipe.transform('regions.main');
            regions.push(vide);

            this.listeService.getAll('regions').pipe(first()).subscribe(elements => {
                for(let e of elements) {
                    if (e.id != element.id) {
                        regions.push(e);
                    }
                }             
            });

            let fields = [];

            let isNew = false;

            if (regionId == "0") {
                isNew = true;
                element = new Region();
            }

            this.breadcrumb.push({
                label: (isNew ? this.translatePipe.transform('element.label') : element.name),
                link: "/regions/" + regionId,
                active: true
            });

            fields.push({
                name: 'name',
                label: this.translatePipe.transform('regions.edition.name'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, !isNew, 50]
            });

            fields.push({
                name: 'etatId',
                label: this.translatePipe.transform('regions.edition.parent'),
                validity: true,
                type: GenFieldType.Dropdown,
                args: [regions, 'id', 'name', false, false]
            });

            fields.push({
                name: 'countryCode',
                label: this.translatePipe.transform('regions.header.code'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, !isNew, 50]
            });

            this.config = {
                name: "regions",
                element: element,
                root: "/regions",
                backId: "",
                isEditable: true,
                isDeletable: true,
                isNew: isNew,
                isEmbeded: false,
                fields: fields
            };
        });
    }
}
