import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenConfig, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';

@Component({
    templateUrl: './gallery.component.html',
    providers: [TranslatePipe]
})
export class GalleryComponent implements OnInit {

    config: GenConfig;
    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.galleries.name"),
            link: "/galleries",
            active: true
        });

        this.config = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: false,
            columns: []
        };
    }

    ngOnInit() {

        let col_description = [];

        this.listeService.getAll('galleries').pipe(first()).subscribe(elements => {

            for (let element of elements) {

                col_description.push({ args: [element.id, element.description] });
            }

            this.config = {
                name: "galleries",
                root: "/galleries",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: false,
                columns: [
                    {
                        name: this.translatePipe.transform('galleries.header.description'),
                        width: 100,
                        data: col_description,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

}
