import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@/_services';

@Component({
    selector: 'app-dispatch-side-menu',
    templateUrl: './dispatch-side-menu.component.html',
    styleUrls: ['./dispatch-side-menu.component.css']
})
export class DispatchSideMenuComponent implements OnInit {

    menuOpened: boolean;
    primaryMenuItems: any[];

    constructor(
        private translationService: TranslationService
    ) { 
        this.menuOpened = true;
        this.primaryMenuItems = [];
    }

    ngOnInit() {
        this.primaryMenuItems.push({
            imgSource: "fas fa-feather-alt",
            description: this.translationService.translate("menu.blog"),
            link: "blog"
        }, 
        {
            imgSource: "fas fa-boxes",
            description: this.translationService.translate("menu.produits"),
            link: "produits"
        }, 
        {
            imgSource: "fas fa-percentage",
            description: this.translationService.translate("menu.discounts"),
            link: "discounts"
        }, 
        {
            imgSource: "fas fa-shopping-bag",
            description: this.translationService.translate("menu.commandes"),
            link: "commandes"
        },
        {
            imgSource: "fas fa-file-upload",
            description: this.translationService.translate("menu.files"),
            link: "files"
        }, 
        {
            imgSource: "fas fa-tags",
            description: this.translationService.translate("menu.categories"),
            link: "categories"
        }, 
        {
            imgSource: "far fa-images",
            description: this.translationService.translate("menu.galleries"),
            link: "galleries"
        }, 
        {
            imgSource: "fas fa-truck",
            description: this.translationService.translate("menu.livraisons"),
            link: "livraisons"
        }, 
        {
            imgSource: "fas fa-university",
            description: this.translationService.translate("menu.taxes"),
            link: "taxes"
        }, 
        {
            imgSource: "fas fa-flag",
            description: this.translationService.translate("menu.regions"),
            link: "regions"
        },  
        {
            imgSource: "fas fa-users",
            description: this.translationService.translate("menu.users"),
            link: "users"
        }, 
        {
            imgSource: "fas fa-bars",
            description: this.translationService.translate("menu.menus"),
            link: "menus"
        },
        {
            imgSource: "fas fa-language",
            description: this.translationService.translate("menu.languages"),
            link: "languages"
        });
    }

    toggleMenu() {
        this.menuOpened = !this.menuOpened;
    }

}
