import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenConfig, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';

@Component({
    templateUrl: './tax.component.html',
    providers: [TranslatePipe]
})
export class TaxComponent implements OnInit {

    config: GenConfig;
    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.taxes.name"),
            link: "/taxes",
            active: true
        });

        this.config = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: false,
            columns: []
        };
    }

    ngOnInit() {

        let col_name = [];
        let col_etat = [];
        let col_default = [];

        this.listeService.getAll('taxes').pipe(first()).subscribe(elements => {

            for (let element of elements) {
                col_name.push({ args: [element.id, element.name] });
                col_etat.push({ args: [element.etat.name] });
                col_default.push({ args: [element.default ? this.translatePipe.transform("taxes.default.true") : this.translatePipe.transform("taxes.default.false")] });
            }

            this.config = {
                name: "taxes",
                root: "/taxes",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: false,
                columns: [
                    {
                        name: this.translatePipe.transform('taxes.header.name'),
                        width: 33,
                        data: col_name,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('taxes.header.etat'),
                        width: 33,
                        data: col_etat,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('taxes.header.default'),
                        width: 33,
                        data: col_default,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

}
