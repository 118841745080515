import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Category } from '@/_models';

@Component({
    templateUrl: './category-edition.component.html',
    providers: [TranslatePipe]
})
export class CategoryEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.categories.name"),
            link: "/categories",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['categoryId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(categoryId) {

        this.listeService.get('categories', categoryId).pipe(first()).subscribe((element: Category) => {

            let categories = [];

            let vide = new Category();
            vide.id = null;
            vide.description = this.translatePipe.transform('categories.main');
            categories.push(vide);

            this.listeService.getAll('categories').pipe(first()).subscribe(elements => {
                for(let e of elements) {
                    if (e.id != element.id) {
                        categories.push(e);
                    }
                }             
            });

            let fields = [];

            let isNew = false;

            if (categoryId == "0") {
                isNew = true;
                element = new Category();
            }

            this.breadcrumb.push({
                label: (isNew ? this.translatePipe.transform('element.label') : element.description),
                link: "/categories/" + categoryId,
                active: true
            });

            fields.push({
                name: 'description',
                label: this.translatePipe.transform('categories.edition.description'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, !isNew, 50]
            });

            fields.push({
                name: 'parentId',
                label: this.translatePipe.transform('categories.edition.parent'),
                validity: true,
                type: GenFieldType.Dropdown,
                args: [categories, 'id', 'description', false, false]
            });

            fields.push({
                name: 'name',
                label: this.translatePipe.transform('categories.edition.name'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, !isNew, 100]
            });

            this.config = {
                name: "categories",
                element: element,
                root: "/categories",
                backId: "",
                isEditable: true,
                isDeletable: true,
                isNew: isNew,
                isEmbeded: false,
                fields: fields
            };
        });
    }
}
