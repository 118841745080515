import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenConfig, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';

@Component({
    templateUrl: './region.component.html',
    providers: [TranslatePipe]
})
export class RegionComponent implements OnInit {

    config: GenConfig;
    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.regions.name"),
            link: "/regions",
            active: true
        });

        this.config = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: false,
            columns: []
        };
    }

    ngOnInit() {

        let col_name = [];
        let col_parent = [];
        let col_code = [];

        this.listeService.getAll('regions').pipe(first()).subscribe(elements => {

            for (let element of elements) {

                let parent = "*";
                col_name.push({ args: [element.id, element.name] });
                if (element.etatId != null) {
                    for (let parElement of elements) {
                        if (element.etatId == parElement.id) {
                            parent = parElement.name;
                        }
                    }
                }
                col_parent.push({ args: [parent] });
                col_code.push({args: [element.countryCode ? element.countryCode : "*"]})
            }

            this.config = {
                name: "regions",
                root: "/regions",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: false,
                columns: [
                    {
                        name: this.translatePipe.transform('regions.header.name'),
                        width: 50,
                        data: col_name,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('regions.header.parent'),
                        width: 50,
                        data: col_parent,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('regions.header.code'),
                        width: 50,
                        data: col_code,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

}
