export class User {

    id: number;
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    activated: boolean;
    roleId: number;

    passwordExpired: boolean;
    token?: string;
}