import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Order } from '@/_models';

@Component({
    templateUrl: './order-edition.component.html',
    providers: [TranslatePipe]
})
export class OrderEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.order.name"),
            link: "/commandes",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['commandeId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(commandeId) {

        this.listeService.get('orders', commandeId).pipe(first()).subscribe((element: Order) => {

            let fields = [];

            let isNew = false;

            if (commandeId == "0") {
                isNew = true;
                element = new Order();
            }

            let statuts = [];

            for(let i =1; i <=5; i++) {
                statuts.push({ statusId: i, description: this.translatePipe.transform('order.status.' + i) })
            }


            this.breadcrumb.push({
                label: (isNew ? this.translatePipe.transform('element.label') : element.id),
                link: "/commandes/" + commandeId,
                active: true
            });

            fields.push({
                name: 'statusId',
                label: this.translatePipe.transform('order.edition.status'),
                validity: true,
                type: GenFieldType.Dropdown,
                args: [statuts, 'statusId', 'description', false, true]
            });

            fields.push({
                name: 'email',
                label: this.translatePipe.transform('order.edition.email'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, true, 100]
            });

            fields.push({
                name: 'address',
                label: this.translatePipe.transform('order.edition.address'),
                validity: true,
                type: GenFieldType.InputMultilignString,
                args: [false, true, 200]
            });

            fields.push({
                name: 'note',
                label: this.translatePipe.transform('order.edition.note'),
                validity: true,
                type: GenFieldType.InputMultilignString,
                args: [false, true, 1000]
            });

            fields.push({
                name: 'amount',
                label: this.translatePipe.transform('order.edition.amount'),
                validity: true,
                type: GenFieldType.InputMoney,
                args: [false, true]
            });

            if (!isNew) {
                if (element.statusId != 5) {
                    fields.push({
                        name: 'refund',
                        label: this.translatePipe.transform('order.edition.refund'),
                        validity: true,
                        type: GenFieldType.RefundButton,
                        args: [element.id]
                    });
                }
                fields.push({
                    name: 'invoice',
                    label: this.translatePipe.transform('order.edition.invoice'),
                    validity: true,
                    type: GenFieldType.PDF,
                    args: [commandeId]
                });
                if (element.pin != null && element.pin.length > 0) {
                    fields.push({
                        name: 'pin',
                        label: this.translatePipe.transform('order.edition.pin'),
                        validity: true,
                        type: GenFieldType.Link,
                        args: ["https://www.purolator.com/fr/expedition/faire-le-suivi-dun-envoi?pins=" + element.pin, "Purolator"]
                    });
                }
            }
            

            this.config = {
                name: "orders",
                element: element,
                root: "/commandes",
                backId: "",
                isEditable: true,
                isDeletable: true,
                isNew: isNew,
                isEmbeded: false,
                fields: fields
            };
        });
    }
}
