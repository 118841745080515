import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { formatDate } from "@angular/common";

import { GenConfig, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';

@Component({
    templateUrl: './user.component.html',
    providers: [TranslatePipe]
})
export class UserComponent implements OnInit {

    config: GenConfig;
    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.users.name"),
            link: "/users",
            active: true
        });

        this.config = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: false,
            columns: []
        };
    }

    ngOnInit() {

        let col_email = [];
        let col_firstname = [];
        let col_lastname = [];
        let col_role = [];

        this.listeService.getAll('users').pipe(first()).subscribe(elements => {

            for (let element of elements) {
                col_email.push({ args: [element.id, element.email] });
                col_firstname.push({ args: [element.firstname] });
                col_lastname.push({ args: [element.lastname] });
                col_role.push({ args: [element.role.description] });
            }

            this.config = {
                name: "users",
                root: "/users",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: false,
                columns: [
                    {
                        name: this.translatePipe.transform('users.header.email'),
                        width: 40,
                        data: col_email,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1] },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('users.header.firstname'),
                        width: 20,
                        data: col_firstname,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('users.header.lastname'),
                        width: 20,
                        data: col_lastname,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('users.header.role'),
                        width: 20,
                        data: col_role,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[0]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

}
