import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbPaginationModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxEditorModule } from 'ngx-editor';
import { RecaptchaModule } from 'ng-recaptcha';
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GenlistComponent } from './_genlist';
import { LoginComponent } from './login';
import { HeaderComponent } from './header';
import { TranslatePipe } from './_pipes';
import { GeneditComponent } from './_genedit';
import { GeninputComponent } from './_geninput';
import { GenmultiselectComponent } from './_genmultiselect';
import { BreadcrumbComponent } from './breadcrumb';
import { FileComponent } from './file/file.component';
import { FileEditionComponent } from './file-edition/file-edition.component';
import { BlogComponent } from './blog/blog.component';
import { BlogEditionComponent } from './blog-edition/blog-edition.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { UserEditionComponent } from './user-edition/user-edition.component';
import { FileSelectionComponent } from './file-selection/file-selection.component';
import { ForgotComponent } from './forgot/forgot.component';
import { DispatchSideMenuComponent } from './dispatch-components/dispatch-side-menu';
import { DispatchSideMenuItemComponent } from './dispatch-components/dispatch-side-menu-item';
import { CategoryComponent } from './category/category.component';
import { CategoryEditionComponent } from './category-edition/category-edition.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryEditionComponent } from './gallery-edition/gallery-edition.component';
import { LanguageComponent } from './language/language.component';
import { MenuComponent } from './menu/menu.component';
import { ProductComponent } from './product/product.component';
import { ProductEditionComponent } from './product-edition/product-edition.component';
import { OrderComponent } from './order/order.component';
import { OrderEditionComponent } from './order-edition/order-edition.component';
import { DiscountComponent } from './discount/discount.component';
import { DiscountEditionComponent } from './discount-edition/discount-edition.component';
import { TaxComponent } from './tax/tax.component';
import { TaxEditionComponent } from './tax-edition/tax-edition.component';
import { RegionComponent } from './region/region.component';
import { RegionEditionComponent } from './region-edition/region-edition.component';
import { LivraisonComponent } from './livraison/livraison.component';
import { LivraisonEditionComponent } from './livraison-edition/livraison-edition.component';
import { UserComponent } from './user/user.component';


// Config
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    GenlistComponent,
    LoginComponent,
    HeaderComponent,
    TranslatePipe,
    GeneditComponent,
    GeninputComponent,
    GenmultiselectComponent,
    BreadcrumbComponent,
    FileComponent ,
    FileEditionComponent,
    BlogComponent,
    BlogEditionComponent,
    UserEditionComponent,
    FileSelectionComponent,
    ForgotComponent,
    DispatchSideMenuComponent,
    DispatchSideMenuItemComponent,
    CategoryComponent,
    CategoryEditionComponent,
    GalleryComponent,
    GalleryEditionComponent,
    LanguageComponent,
    MenuComponent,
    ProductComponent,
    ProductEditionComponent,
    OrderComponent,
    OrderEditionComponent,
    DiscountComponent,
    DiscountEditionComponent,
    TaxComponent,
    TaxEditionComponent,
    RegionComponent,
    RegionEditionComponent,
    LivraisonComponent,
    LivraisonEditionComponent,
    UserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbPaginationModule,
    NgbModalModule,
    NgxEditorModule,
    RecaptchaModule,
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
