import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Discount } from '@/_models';

@Component({
    templateUrl: './discount-edition.component.html',
    providers: [TranslatePipe]
})
export class DiscountEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.discounts.name"),
            link: "/discounts",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['discountId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(discountId) {

        this.listeService.get('discounts', discountId).pipe(first()).subscribe((element: Discount) => {

            let fields = [];
            let isNew = false;

            let rules = [];
            rules.push({ id: 1, description: this.translatePipe.transform('discounts.rules.percent') });
            rules.push({ id: 2, description: this.translatePipe.transform('discounts.rules.amount') });

            if (discountId == "0") {
                isNew = true;
                element = new Discount();
            }

            this.breadcrumb.push({
                label: (isNew ? this.translatePipe.transform('element.label') : element.code),
                link: "/discounts/" + discountId,
                active: true
            });

            fields.push({
                name: 'code',
                label: this.translatePipe.transform('discounts.edition.code'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, true]
            });

            fields.push({
                name: 'min_price',
                label: this.translatePipe.transform('discounts.edition.min_price'),
                validity: true,
                type: GenFieldType.InputMoney,
                args: [false, true]
            });

            fields.push({
                name: 'rule',
                label: this.translatePipe.transform('discounts.edition.rule'),
                validity: true,
                type: GenFieldType.Dropdown,
                args: [rules, 'id', 'description', false, true]
            });

            fields.push({
                name: 'apply',
                label: this.translatePipe.transform('discounts.edition.apply'),
                validity: true,
                type: GenFieldType.InputMoney,
                args: [false, true]
            });

            this.config = {
                name: "discounts",
                element: element,
                root: "/discounts",
                backId: "",
                isEditable: true,
                isDeletable: true,
                isNew: isNew,
                isEmbeded: false,
                fields: fields
            };
        });
    }
}
