import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './menu.component.html',
    providers: [TranslatePipe],
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

    config: any;

    elements: any[] = [];

    newelement: any = { elements: ["","",""], category: "" };

    isChange: boolean = false;

    breadcrumb: GenBreadcrumb[];

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService,
        private http: HttpClient,
        private modalService: NgbModal) {

        this.breadcrumb = [];
        this.breadcrumb.push({
            label: translateService.translate("page.manus.name"),
            link: "/menus",
            active: true
        });
    }

    ngOnInit() {
        this.http.get(environment.apiUrl + "/config?config=menu").subscribe(response => {
            this.config = response;
            for(let category in this.config) {
                this.elements.push(category);
            }
        });
        this.isChange = false;
    }

    onBlurMethod(categoryName, i, j, value){
        this.config[categoryName][i][j] = value;
        this.isChange = true;
    }

    deleteElement(categoryName, index) {
        this.config[categoryName].splice(index, 1);
        this.isChange = true;
    }

    deleteCategory(categoryName) {
        delete this.config[categoryName];
        
        this.elements = this.elements.filter(function(value, index, arr){
            return value != categoryName;
        });
        this.isChange = true;
    }

    save() {
        this.http.post(environment.apiUrl + "/config?config=menu", this.config).subscribe(response => {});
        this.isChange = false;
    }

    add() {
        if (this.config[this.newelement.category] == null) {
            this.config[this.newelement.category] = [];
            this.elements.push(this.newelement.category);
        }
        let add = [];
        for(let element of this.newelement.elements) {
            add.push(element);
        }
        this.config[this.newelement.category].push(add);
        this.newelement = { elements: ["","",""], category: "" };
        this.modalService.dismissAll();
        this.isChange = true;
    }

    openCategory(category, modal) {
        this.newelement.category = category;
        this.modalService.open(modal, { size: 'lg' }).result.then((result) => { this.newelement = { elements: ["","",""], category: "" }; }, (reason) => { this.newelement = { elements: ["","",""], category: "" }; });
    }

    open(modal) {
        this.modalService.open(modal, { size: 'lg' }).result.then((result) => { this.newelement = { elements: ["","",""], category: "" }; }, (reason) => { this.newelement = { elements: ["","",""], category: "" }; });
    }
}
