import { formatDate } from '@angular/common';
import { Fichier } from './fichier';

export class Article {
    
    id: number;
    title: string;
    summary: string;
    lang: string = 'fr';
    imageId: number;
    image: Fichier;
    author: string;
    body: string;
    date: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');;
    hidden: boolean = false;
}