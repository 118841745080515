export enum GenFieldType {
    
    InputString = 'inputstring',
    InputMultilignString = 'inputmultilignstring',
    InputNumber = 'inputnumber',
    InputDate = 'inputdate',
    InputDownload = 'inputdownload',
    InputUpload = 'inputupload',
    Dropdown = 'dropdown',
    Checkbox = 'checkbox',
    Multiselect = 'multiselect',
    ImgPreview = 'imgpreview',
    GenSelect = 'genselect',
    InputEditor = 'inputeditor',
    InputMoney = 'inputmoney',
    InputTax = 'inputtax',
    PDF = 'pdf',
    RefundButton = 'refundbutton',
    Link = 'link'
}