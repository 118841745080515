import { Injectable } from '@angular/core';

import fr from '@/../assets/lang/fr.json';
import en from '@/../assets/lang/en.json';

export class TranslationSet {
    public language: string;
    public values: {[key: string]: string} = {};
 }

@Injectable({ providedIn: 'root' })
export class TranslationService {

    public languages = ['fr', 'en'];

    public default = 'fr';

    public language = this.default;

    private dictionary: {[key: string]: any} = { };

    constructor() {
        this.dictionary['fr'] = fr;
        this.dictionary['en'] = en;
        this.language = this.getSelectedLanguage();
    }

    getSelectedLanguage():string {
        let language = localStorage.getItem('lang');
        if(language == null){
            localStorage.setItem('lang', 'en');
            language = 'en';
        } else if (this.languages.indexOf(language) < 0) {
            localStorage.setItem('lang', 'en');
            language = 'en';
        }
        return language;
    }

    setSelectedLanguage() {
        if (this.language != 'en') {
            localStorage.setItem('lang', 'en');
            this.translate('lang');
            this.language = 'en';
        } else {
            localStorage.setItem('lang', 'fr');
            this.translate('lang');
            this.language = 'fr';
        }
    }

    translate(key: string): string {
        if (this.dictionary[this.language] != null) {
            return this.dictionary[this.language].values[key];
        }
    }
}