import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Gallery } from '@/_models';

@Component({
    templateUrl: './gallery-edition.component.html',
    providers: [TranslatePipe]
})
export class GalleryEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.galleries.name"),
            link: "/galleries",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['galleryId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(galleryId) {

        this.listeService.get('galleries', galleryId).pipe(first()).subscribe((element: Gallery) => {

            this.listeService.getAll('categories').pipe(first()).subscribe(categories => {

                this.listeService.getAll('files').pipe(first()).subscribe(files => {

                    let fields = [];

                    let isNew = false;

                    if (galleryId == "0") {
                        isNew = true;
                        element = new Gallery();
                    }

                    this.breadcrumb.push({
                        label: (isNew ? this.translatePipe.transform('element.label') : element.description),
                        link: "/galleries/" + galleryId,
                        active: true
                    });

                    fields.push({
                        name: 'description',
                        label: this.translatePipe.transform('galleries.edition.description'),
                        validity: true,
                        type: GenFieldType.InputString,
                        args: [false, !isNew, 50]
                    });

                    fields.push({
                        name: 'categories',
                        label: this.translatePipe.transform('galleries.edition.categories'),
                        validity: true,
                        type: GenFieldType.Multiselect,
                        args: [categories, 'id', 'description', this.translatePipe.transform('galleries.edition.categories')]
                    });

                    fields.push({
                        name: 'images',
                        label: this.translatePipe.transform('galleries.edition.images'),
                        validity: true,
                        type: GenFieldType.Multiselect,
                        args: [files, 'id', 'name', this.translatePipe.transform('galleries.edition.images')]
                    });

                    this.config = {
                        name: "galleries",
                        element: element,
                        root: "/galleries",
                        backId: "",
                        isEditable: true,
                        isDeletable: true,
                        isNew: isNew,
                        isEmbeded: false,
                        fields: fields
                    };

                });
                
            });
        });
    }
}
