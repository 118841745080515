import { Category, Fichier } from '.';

export class Produit {
    
    id: number;
    title: string;
    summary: string;
    body: string;
    price: number = 0;
    weight: number = 0;
    slug: string;
    image: Fichier;
    categories: Category[] = [];
    images: Fichier[] = [];
    hidden: boolean = false;
    isDeliverable: boolean = true;
}