import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService, AuthenticationService } from '@/_services';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {

    public password: string = "";
    public confirm: string = "";

    public loading: boolean = false;
    public errorMessage: boolean = false;
    public successMessage: boolean = false;

    private token: string = "";
    private captchaResponse: string;

    recaptcha: string = environment.recaptcha;

    sub: any;

    constructor(private authenticationService: AuthenticationService,
        private route: ActivatedRoute) { }

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnDestroy() {
        if (this.sub != null) {
            this.sub.unsubscribe();
        }
    }

    resolved(captchaResponse: string) {
        this.captchaResponse = captchaResponse;
    }

    changePassword() {

        if (this.password.length > 8 && this.password == this.confirm) {

            this.loading = true;
            this.errorMessage = false;
            this.successMessage = false;

            this.authenticationService.changePassword(this.token, this.captchaResponse, this.password)
                .pipe(first())
                .subscribe(
                    data => {
                        this.successMessage = true;
                        this.loading = false;
                    },
                    error => {
                        console.error(error);
                        this.errorMessage = true;
                        this.loading = false;
                    });
        } else {
            this.errorMessage = true;
            this.successMessage = false;
        }
    }

}
