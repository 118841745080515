import { Component, OnInit, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { TranslationService, AuthenticationService, ListeService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, User } from '@/_models';
import { ActivatedRoute } from '@angular/router';


@Component({
    templateUrl: './user-edition.component.html',
    providers: [TranslatePipe]
})
export class UserEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    currentUser: User;
    breadcrumb: GenBreadcrumb[];
    sub: any;

    constructor(
        private listeService: ListeService,
        private translatePipe: TranslatePipe,
        private route: ActivatedRoute,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.users.name"),
            link: "/users",
            active: true
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: false,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['userId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(userId) {

        let fields = [];

        this.listeService.get('users', userId).pipe(first()).subscribe(element => {

            this.listeService.getAll('roles').pipe(first()).subscribe(roles => {

                fields.push({
                    name: 'firstname',
                    label: this.translatePipe.transform('user.edition.firstname'),
                    validity: true,
                    type: GenFieldType.InputString,
                    args: [false, true, 50]
                });

                fields.push({
                    name: 'lastname',
                    label: this.translatePipe.transform('user.edition.lastname'),
                    validity: true,
                    type: GenFieldType.InputString,
                    args: [false, true, 50]
                });

                fields.push({
                    name: 'password',
                    label: this.translatePipe.transform('user.edition.password'),
                    validity: true,
                    type: GenFieldType.InputString,
                    args: [false, false, 50]
                });

                fields.push({
                    name: 'email',
                    label: this.translatePipe.transform('user.edition.email'),
                    validity: true,
                    type: GenFieldType.InputString,
                    args: [false, true, 50]
                });

                fields.push({
                    name: 'activated',
                    label: this.translatePipe.transform('user.edition.activated'),
                    validity: true,
                    type: GenFieldType.Checkbox,
                    args: [false, true]
                });

                fields.push({
                    name: 'roleId',
                    label: this.translatePipe.transform('user.edition.role'),
                    validity: true,
                    type: GenFieldType.Dropdown,
                    args: [
                        roles,
                        'id',
                        'description',
                        false,
                        true
                    ]
                });

                this.config = {
                    name: "users",
                    element: element,
                    root: "/users",
                    backId: "",
                    isEditable: true,
                    isDeletable: false,
                    isNew: false,
                    isEmbeded: false,
                    fields: fields
                };
            });
        });
    }
}
