import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Fichier } from '@/_models';

@Component({
    templateUrl: './file-edition.component.html',
    providers: [TranslatePipe]
})
export class FileEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.files.name"),
            link: "/files",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['fileId']);
            console.log(params['fileId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(fileId) {

        this.listeService.get('files', fileId).pipe(first()).subscribe((element: Fichier) => {

            let fields = [];

            let isNew = false;

            if (fileId == "0") {
                isNew = true;
                element = new Fichier();
            }

            this.breadcrumb.push({
                label: (isNew ? this.translatePipe.transform('element.label') : element.name),
                link: "/files/" + fileId,
                active: true
            });

            fields.push({
                name: 'name',
                label: this.translatePipe.transform('files.edition.name'),
                validity: true,
                type: GenFieldType.InputString,
                args: [false, !isNew, 50]
            });

            fields.push({
                name: 'file',
                label: this.translatePipe.transform('files.edition.file'),
                validity: true,
                type: GenFieldType.InputUpload,
                args: [false, isNew, isNew]
            });

            if (!isNew) {
                element.preview = environment.dataUrl + "/sd/" + element.id + ".webp" + "?ts=" + new Date().getTime();
                fields.push({
                    name: 'preview',
                    label: this.translatePipe.transform('files.edition.preview'),
                    validity: true,
                    type: GenFieldType.ImgPreview,
                    args: []
                });
            }

            this.config = {
                name: "files",
                element: element,
                root: "/files",
                backId: "",
                isEditable: true,
                isDeletable: true,
                isNew: isNew,
                isEmbeded: false,
                fields: fields
            };
        });
    }
}
