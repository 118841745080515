import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import { GenEdit } from '@/_interfaces';
import { ListeService } from '@/_services';

@Component({
    selector: 'genedit',
    templateUrl: './genedit.component.html',
    styleUrls: ['./genedit.component.css']
})
export class GeneditComponent implements OnInit {

    @Input('config') config: GenEdit;
    submitted = false;
    @ViewChild('modalError', { static: true }) modalError: any;

    @Output() changeScreen = new EventEmitter();

    constructor(
        private listeService: ListeService,
        private router: Router,
        private modalService: NgbModal
    ) { }

    ngOnInit() {
    }

    back() {
        if (this.config.isEmbeded) {

            this.changeScreen.emit();
        } else {

            if (this.config.backId.length > 0 && this.config.element[this.config.backId] > 0) {
                this.router.navigate([this.config.root + "/" + this.config.element[this.config.backId]]);
            } else {
                this.router.navigate([this.config.root]);
            }
        }
    }

    fileElement(files) {
        var re = /(?:\.([^.]+))?$/;
        this.config.element.ext = re.exec(files.item(0).name)[1].toLowerCase();
        if (this.config.element.name == null || this.config.element.name.length == 0) {
            this.config.element.name = files.item(0).name;
        }
        this.config.element.file = files;
    }

    checkValidity() {
        let isValid = true;
        for (let field of this.config.fields) {
            if (field.type == 'inputstring' && field.args[1]) {
                if (this.config.element[field.name] == null || this.config.element[field.name].length == 0) {
                    field.validity = false;
                    isValid = false;
                }
            } else if (field.type == 'dropdown' && field.args[4]) {
                if (this.config.element[field.name] == null || this.config.element[field.name].length == 0) {
                    field.validity = false;
                    isValid = false;
                }
            } else if (field.type == 'inputupload' && field.args[1]) {
                if (this.config.element[field.name] == null || this.config.element[field.name].length == 0) {
                    field.validity = false;
                    isValid = false;
                }
            }
        }
        return isValid;
    }

    edit() {
        if (this.config.isEditable || this.config.isNew) {

            this.submitted = true;

            if (!this.checkValidity()) {
                return;
            }
            if (!this.config.isNew) {
                this.listeService.update(this.config.name, this.config.element.id, this.config.element).pipe(first()).subscribe((result) => {
                    this.back();
                }, (reason) => {
                    console.error(reason);
                    this.modalService.open(this.modalError, { ariaLabelledBy: 'modal-error-title' }).result.then(
                        (result) => { }, (reason) => { }
                    );
                });
            } else {
                console.log(this.config.element);
                this.listeService.create(this.config.name, this.config.element).pipe(first()).subscribe((result) => {
                    this.back();
                }, (reason) => {
                    console.error(reason);
                    this.modalService.open(this.modalError, { ariaLabelledBy: 'modal-error-title' }).result.then(
                        (result) => { }, (reason) => { }
                    );
                });
            }
        }
    }

    delete(model) {
        this.modalService.open(model, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.listeService.delete(this.config.name, this.config.element.id).pipe(first()).subscribe(element => this.back());
        }, (reason) => { });
    }
}
