import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenEdit, GenBreadcrumb } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { GenFieldType, Tax } from '@/_models';

@Component({
    templateUrl: './tax-edition.component.html',
    providers: [TranslatePipe]
})
export class TaxEditionComponent implements OnInit, OnDestroy {

    config: GenEdit;
    sub: any;
    breadcrumb: GenBreadcrumb[];

    constructor(
        private listeService: ListeService,
        private route: ActivatedRoute,
        private translatePipe: TranslatePipe,
        private translateService: TranslationService
    ) {

        this.breadcrumb = [];

        this.breadcrumb.push({
            label: translateService.translate("page.taxes.name"),
            link: "/taxes",
            active: false
        });

        this.config = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: false,
            fields: []
        };
    }

    ngOnInit() {

        this.sub = this.route.params.subscribe(params => {
            this.initialize(params['taxId']);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    initialize(taxId) {

        this.listeService.get('taxes', taxId).pipe(first()).subscribe((element: Tax) => {

            let regions = [];

            this.listeService.getAll('regions').pipe(first()).subscribe(elements => {
                
                for(let e of elements) {
                    regions.push(e);
                }             
            

                let fields = [];

                let isNew = false;

                if (taxId == "0") {
                    isNew = true;
                    element = new Tax();
                }

                this.breadcrumb.push({
                    label: (isNew ? this.translatePipe.transform('element.label') : element.name),
                    link: "/taxes/" + taxId,
                    active: true
                });

                fields.push({
                    name: 'name',
                    label: this.translatePipe.transform('taxes.edition.name'),
                    validity: true,
                    type: GenFieldType.InputString,
                    args: [false, !isNew, 50]
                });

                fields.push({
                    name: 'etatId',
                    label: this.translatePipe.transform('taxes.edition.etat'),
                    validity: true,
                    type: GenFieldType.Dropdown,
                    args: [regions, 'id', 'name', false, false]
                });

                fields.push({
                    name: 'rate',
                    label: this.translatePipe.transform('taxes.edition.rate'),
                    validity: true,
                    type: GenFieldType.InputTax,
                    args: [false, !isNew, 100]
                });

                fields.push({
                    name: 'ordre',
                    label: this.translatePipe.transform('taxes.edition.ordre'),
                    validity: true,
                    type: GenFieldType.InputNumber,
                    args: [false, !isNew, 100]
                });

                console.log(element.default);

                fields.push({
                    name: 'default',
                    label: this.translatePipe.transform('taxes.header.default'),
                    validity: true,
                    type: GenFieldType.Checkbox,
                    args: [false, true]
                });

                this.config = {
                    name: "taxes",
                    element: element,
                    root: "/taxes",
                    backId: "",
                    isEditable: true,
                    isDeletable: true,
                    isNew: isNew,
                    isEmbeded: false,
                    fields: fields
                };
            });
        });
    }
}
