import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dispatch-side-menu-item',
    templateUrl: './dispatch-side-menu-item.component.html',
    styleUrls: ['./dispatch-side-menu-item.component.css']
})
export class DispatchSideMenuItemComponent implements OnInit {

    @Input()
    imgSource: string;

    @Input()
    description: string;

    @Input()
    link: string;

    @Input()
    menuOpened: boolean;

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
    }

    followLink():void{
        this.router.navigate(['/' + this.link]);
    }

}
