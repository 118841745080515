import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

import { AuthenticationService } from './_services';
import { User } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    currentUser: User;
    version: string = environment.version;
    year: number;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.year = (new Date()).getFullYear();
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}
