import { Component, OnInit, Input } from '@angular/core';

import { GenField } from '@/_interfaces';
import { TranslatePipe } from '@/_pipes';

@Component({
    selector: 'genmultiselect',
    templateUrl: './genmultiselect.component.html',
    styleUrls: ['./genmultiselect.component.css']
})
export class GenmultiselectComponent implements OnInit {

    @Input('field') field: GenField;
    @Input('element') element: any;

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    constructor(private translatePipe: TranslatePipe) { }

    ngOnInit() {

        this.dropdownList = this.field.args[0];
        this.selectedItems = this.getElements();
        this.dropdownSettings = {
            singleSelection: false,
            idField: this.field.args[1],
            textField: this.field.args[2],
            selectAllText: this.translatePipe.transform('entreprise.edition.select'),
            unSelectAllText: this.translatePipe.transform('entreprise.edition.unselect'),
            searchPlaceholderText: this.translatePipe.transform('entreprise.edition.find'),
            itemsShowLimit: 10,
            allowSearchFilter: true
        };
        this.setElements();
    }

    getElements(): any[] {
        let elements = [];
        for (let element of this.element[this.field.name]) {

            elements.push(element);
        }
        return elements;
    }

    setElements() {
        this.element[this.field.name] = [];

        for (let item of this.selectedItems) {

            this.element[this.field.name].push(item);
        }
    }

    setElementsSelected(items) {
        this.element[this.field.name] = [];

        for (let item of items) {

            this.element[this.field.name].push(item);
        }
    }

    onItemSelect(item: any) {
        this.setElements();
    }
    onSelectAll(items: any) {
        this.setElementsSelected(items);
    }
    onDeSelect(item: any) {
        this.setElements();
    }
    onDeSelectAll(item: any) {
        this.setElementsSelected([]);
    }
    onDropdownClose() {
        this.setElements();
    }
}
