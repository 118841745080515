import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { AuthenticationService, TranslationService } from '@/_services';

@Component({ 
    templateUrl: 'login.component.html' ,
    styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loginFormShown: boolean;
    loginSubmitted: boolean = false;
    loginError: boolean = false;
    forgotForm: FormGroup;
    forgotFormShown: boolean;
    forgotSubmitted: boolean = false;
    forgotSuccess: boolean = false;
    forgotError: boolean = false;
    loading = false;
    returnUrl: string;

    recaptcha: string = environment.recaptcha;

    private captchaResponse: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private translationService: TranslationService
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            loginUsername: ['', Validators.required],
            loginPassword: ['', Validators.required]
        });
        this.loginFormShown = true;

        this.forgotForm = this.formBuilder.group({
            forgotEmail:    ['', Validators.required]
        });
        this.forgotFormShown = false;

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    get p() { return this.forgotForm.controls; }

    onSubmitLogin() {
        this.loginSubmitted = true;
        this.forgotError = false;
        this.forgotSuccess = false;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.loginUsername.value, this.f.loginPassword.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.router.navigate([this.returnUrl]);
                },
                error => {
                    console.error(error);
                    this.loginError = true;
                    this.loading = false;
                });
    }

    changeLangue(){
        this.translationService.setSelectedLanguage();
    }

    getLanguage() :string{
        return this.translationService.getSelectedLanguage();
    }

    showForgotForm(){
        this.forgotFormShown = true;
        this.loginFormShown = false;
    }

    showLoginForm(){
        this.forgotFormShown = false;
        this.loginFormShown = true;
    }

    resolved(captchaResponse: string) {
        this.captchaResponse = captchaResponse;
    }

    onSubmitForgot(){
        if (this.forgotForm.invalid) {
            return;
        }

        this.loading = true;
        this.loginError = false;

        this.authenticationService.forgotPassword(this.p.forgotEmail.value, this.translationService.language, this.captchaResponse)
            .pipe(first())
            .subscribe(
                data => {
                    this.forgotSuccess = true;
                    this.forgotError = false;
                    this.loading = false;
                    
                    this.showLoginForm();
                    this.p.forgotEmail.reset();
                },
                error => {
                    console.error(error);
                    this.forgotError = true;
                    this.forgotSuccess = false;
                    this.loading = false;
                });
    }
}
