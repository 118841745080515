import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { GenConfig, GenBreadcrumb, GenEdit } from '@/_interfaces';
import { ListeService, TranslationService } from '@/_services';
import { TranslatePipe } from '@/_pipes';
import { Fichier, GenFieldType } from '@/_models';

@Component({
    selector: 'app-file-selection',
    templateUrl: './file-selection.component.html',
    providers: [TranslatePipe]
})
export class FileSelectionComponent implements OnInit {

    @Output() selected = new EventEmitter();

    public isAjouter: boolean = false;

    configListe: GenConfig;
    configAjouter: GenEdit;

    constructor(private listeService: ListeService,
        private translatePipe: TranslatePipe) {

        this.configListe = {
            name: "",
            root: "",
            indexColumnSort: 0,
            autoRefreshData: false,
            addLimit: 0,
            countLimit: 0,
            isSearch: false,
            isEmbeded: true,
            columns: []
        };

        this.configAjouter = {
            name: "",
            element: {},
            root: "",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: true,
            isEmbeded: true,
            fields: []
        };
    }

    ngOnInit() {

        this.initializeListe();
    }

    initializeListe() {

        let col_name = [];
        let col_preview = [];

        this.listeService.getAll('files').pipe(first()).subscribe(elements => {

            for (let element of elements) {

                col_name.push({ args: [element.id, element.name] });
                col_preview.push({ args: [environment.dataUrl + "/sm/" + element.id + ".webp" + "?ts=" + new Date().getTime()] });
            }

            this.configListe = {
                name: "files",
                root: "/files",
                indexColumnSort: 0,
                autoRefreshData: false,
                addLimit: -1,
                countLimit: 0,
                isSearch: false,
                isEmbeded: true,
                columns: [
                    {
                        name: this.translatePipe.transform('files.header.name'),
                        width: 90,
                        data: col_name,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return args[1]; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    },
                    {
                        name: this.translatePipe.transform('files.header.preview'),
                        width: 10,
                        data: col_preview,
                        isLink: true,
                        isVisible: true,
                        displayFunc: function (args) { return "<img src='" + args[0] + "' class='img-fluid'>"; },
                        sortableFunc: function (arg) { return (`${arg}`).toLowerCase(); }
                    }
                ]
            };

        });
    }

    initializeAjouter() {
        
        let fields = [];

        let isNew = true;

        let element = new Fichier();

        fields.push({
            name: 'name',
            label: this.translatePipe.transform('files.edition.name'),
            validity: true,
            type: GenFieldType.InputString,
            args: [false, !isNew, 50]
        });

        fields.push({
            name: 'file',
            label: this.translatePipe.transform('files.edition.file'),
            validity: true,
            type: GenFieldType.InputUpload,
            args: [false, isNew, isNew]
        });

        this.configAjouter = {
            name: "files",
            element: element,
            root: "/files",
            backId: "",
            isEditable: true,
            isDeletable: true,
            isNew: isNew,
            isEmbeded: true,
            fields: fields
        };
    }

    selectedRow(fileId) {
        this.listeService.get('files', fileId).pipe(first()).subscribe((element: Fichier) => {

            this.selected.emit(element);
        });
    }

    changeScreen() {

        if (this.isAjouter) {
            this.initializeListe();
        } else {
            this.initializeAjouter();
        }
        this.isAjouter = !this.isAjouter;
    }
}

