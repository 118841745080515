import { Component, OnInit, Input } from '@angular/core';

import { GenBreadcrumb } from '@/_interfaces';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input('config') elements: GenBreadcrumb[];

  constructor() { }

  ngOnInit() {
  }

}
