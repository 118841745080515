export class Order {
    
    id: number;
    statusId: number;
    lastname: string;
    firstname: string;
    email: string;
    address: string;
    note: string;
    amount: number;
    ref: string;
    pin: string;
}